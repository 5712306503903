export default [
  {
    aboutContent:
      "Born and raised in a small town in New York, there wasn’t much to do besides sports and day dream, so naturally I did both./n\nI’m interested in relating to the audience, so stories about the up's and down's of life and how we navigate through it all speak to me. I don’t take myself too seriously and love to laugh and joke around (life’s too short to not have fun!)./n\nWhen I’m not getting lost in a film or a TV series, I delve into the sports world. The New York Jets are my team and I’m not ashamed to say it! Being a die-hard Jets fan has definitely prepared me for the entertainment industry. I know how to be optimistic even in the toughest times, and trust me, it’s a TOUGH sports life./n\nOh, and if you couldn’t tell by the side photo, I’m a big K-pop fan. Now back to your regular scheduled programming.",
    aboutPhotoUrl:
      "https://i.ibb.co/JHGnjFy/C4571707-E404-4251-9-C23-CE7-B6016-CC7-A-1.jpg",
    aboutTitle: "About Me",
    contactMeEmail: "dchong128@gmail.com",
    contactMePhone: "",
    contactMeSubtitle: "Want to say hi? Feel free to contact me!",
    contactMeTitle: "Contact Me",
    footerText: "Devin Chong - 2021",
    galleryImageUrl1:
      "https://i.ibb.co/MBKRmQ7/854-C67-C2-333-B-437-C-9-F50-7142-DB0-F6-CC0.jpg",
    galleryImageUrl2:
      "https://i.ibb.co/Stp614R/27-BA0247-0-D6-D-47-FD-9-FBE-4559-FA49062-C.jpg",
    galleryImageUrl3:
      "https://i.ibb.co/DgX50m1/52-F33-D53-2-EF5-4-AB1-884-E-92-AB91809101.jpg",
    galleryImageUrl4:
      "https://i.ibb.co/jfwgTpm/036775-DE-CED1-4005-98-F6-65647299-A3-F5.jpg",
    galleryImageUrl5:
      "https://i.ibb.co/BG3Tqng/B32-F152-C-CBDE-4-F74-8-C0-E-B21890-B08-B57.jpg",
    galleryImageUrl6:
      "https://i.ibb.co/T4NhFs1/F80-BD83-D-A57-F-4603-810-A-3-ECA73-F27828.jpg",
    galleryImageUrl7:
      "https://i.ibb.co/PjpkZfH/2-E5-D0203-C46-F-43-FE-B505-75-EAF5015-DB7.jpg",
    galleryImageUrl8: "",
    galleryImageUrl9: "",
    galleryImageUrl10: "",
    gallerySubtitle: "My portraits",
    galleryTitle: "Gallery",
    headerLogoDarkUrl:
      "https://i.ibb.co/vZSGSZc/Screen-Shot-2021-09-12-at-12-15-29-PM.png",
    headerLogoLightUrl:
      "https://i.ibb.co/0jCGHYh/Screen-Shot-2021-09-12-at-12-14-51-PM.png",
    quickBlurbContent:
      "Welcome to my page! Here you can follow me on my artistic journey (whatever that means), and get to know a little bit about me. Feel free to explore or start at my About Me to get a better idea of who I am! ",
    quickBlurbEmail: "dchong128@gmail.com",
    quickBlurbFlickrUrl:
      "https://www.flickr.com/photos/193926450@N04/albums/72157719831976327",
    quickBlurbInstagramUrl: "https://www.instagram.com/devin.chong/",
    quickBlurbPhotoUrl:
      "https://i.ibb.co/G5KGvkY/7-EF774-D2-6-B87-4-F19-8-FF5-8377804-FF865-2.jpg",
    quickBlurbTitle: "Hi, I'm Devin     ",
    quickBlurbTwitterUrl: "",
    quickBlurbYouTubeUrl: "",
    reelsSubtitle: "Coming soon!",
    reelsTitle: "Reels",
    reelsVideo1Description: "",
    reelsVideo1Id: "",
    reelsVideo1Title: "",
    reelsVideo2Description: "",
    reelsVideo2Id: "",
    reelsVideo2Title: "",
    reelsVideo3Description: "",
    reelsVideo3Id: "",
    reelsVideo3Title: "",
    resumeLink:
      "https://drive.google.com/file/d/1qi7xOfxL3GL0Ffkwte1-uUrb8yZSldv4/view?usp=drivesdk",
    username: "Devin Chong"
  }
];
