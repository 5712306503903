import React, {useContext} from "react";
import Headroom from "react-headroom";
import "./Header.scss";
import ToggleSwitch from "../ToggleSwitch/ToggleSwitch";
import StyleContext from "../../contexts/StyleContext";
import DataContext from "../../containers/DataContext";

function Header() {
  const data = useContext(DataContext);
  const {username, headerLogoLightUrl, headerLogoDarkUrl, resumeLink} =
    data[0] || {};

  const {isDark} = useContext(StyleContext);
  const logoUrl = isDark ? headerLogoDarkUrl : headerLogoLightUrl;

  return (
    <Headroom>
      <header className={isDark ? "dark-menu header" : "header"}>
        <a href="/" className="logo">
          {logoUrl ? (
            <img alt="Devin Logo" src={logoUrl} className="logo-image" />
          ) : (
            <span className="logo-name">{username}</span>
          )}
        </a>

        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label
          className="menu-icon"
          htmlFor="menu-btn"
          style={{color: "white"}}
        >
          <span className={isDark ? "navicon navicon-dark" : "navicon"}></span>
        </label>
        <ul className={isDark ? "dark-menu menu" : "menu"}>
          <li>
            <a href="#about">About</a>
          </li>

          <li>
            <a href="#reels">Reels</a>
          </li>

          <li>
            <a href="#gallery">Gallery</a>
          </li>

          <li>
            <a href={resumeLink} target="_blank" rel="noopener noreferrer">
              Resume
            </a>
          </li>

          <li>
            <a href="#contact">Contact Me</a>
          </li>
          <li>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a>
              <ToggleSwitch />
            </a>
          </li>
        </ul>
      </header>
    </Headroom>
  );
}
export default Header;
