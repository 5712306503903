import React, {useContext} from "react";
import "./Reels.scss";
import ReelsCard from "../../components/reelsCard/ReelsCard";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import DataContext from "../DataContext";

const formatReelsVideos = data => {
  const formattedReelsVideosData = [];
  for (let i = 0; i < 3; i++) {
    const prefix = "reelsVideo";
    const id = data[`${prefix}${i + 1}Id`] || null;
    const title = data[`${prefix}${i + 1}Title`] || null;
    const description = data[`${prefix}${i + 1}Description`] || null;

    formattedReelsVideosData.push({id, title, description});
  }
  return formattedReelsVideosData;
};

export default function Reels() {
  const {isDark} = useContext(StyleContext);
  const data = useContext(DataContext);
  const {
    reelsTitle,
    reelsSubtitle,
    reelsVideo1Title,
    reelsVideo1Description,
    reelsVideo1Id,
    reelsVideo2Title,
    reelsVideo2Description,
    reelsVideo2Id,
    reelsVideo3Title,
    reelsVideo3Description,
    reelsVideo3Id
  } = data[0] || {};

  const formattedReelsVideosData = formatReelsVideos({
    reelsVideo1Title,
    reelsVideo1Description,
    reelsVideo1Id,
    reelsVideo2Title,
    reelsVideo2Description,
    reelsVideo2Id,
    reelsVideo3Title,
    reelsVideo3Description,
    reelsVideo3Id
  });

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="reels">
        <div className="reels-main-div">
          <div className="reels-header">
            <h1
              className={
                isDark
                  ? "dark-mode heading reels-heading"
                  : "heading reels-heading"
              }
            >
              {reelsTitle}
            </h1>

            <p
              className={
                isDark
                  ? "dark-mode subTitle reels-subtitle"
                  : "subTitle reels-subtitle"
              }
            >
              {reelsSubtitle}
            </p>
          </div>
          <div className="reels-cards-div">
            {formattedReelsVideosData.map(datum => {
              const doesNotHaveValue = Object.values(datum).some(val => !val);

              if (doesNotHaveValue) return null;

              const {id, title, description} = datum;
              return (
                <ReelsCard
                  key={id}
                  isDark={isDark}
                  cardInfo={{
                    id,
                    title,
                    description
                  }}
                />
              );
            })}
          </div>
        </div>
      </div>
    </Fade>
  );
}
