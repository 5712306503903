import React, {useEffect, useState} from "react";
import Header from "../components/header/Header";
import useGoogleSheets from "use-google-sheets";

import Greeting from "./greeting/Greeting";
import About from "./about/About";
import Footer from "../components/footer/Footer";
import Top from "./topbutton/Top";
import DataContext from "./DataContext";
import Reels from "./reels/Reels";
import Gallery from "./gallery/Gallery";
import Contact from "./contact/Contact";

import {StyleProvider} from "../contexts/StyleContext";
import "./Main.scss";

import backupData from "../utils/backupData";

const Main = () => {
  const [isDark, setDarkMode] = useState(false);
  const [data, setData] = useState(backupData);

  const {data: googleSheetData} = useGoogleSheets({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    sheetId: process.env.REACT_APP_GOOGLE_SHEETS_ID
  });

  useEffect(() => {
    if (googleSheetData && googleSheetData.length) {
      setData(googleSheetData[0].data);
    }
  }, [googleSheetData]);

  const changeTheme = () => {
    setDarkMode(!isDark);
  };

  return (
    <div className={isDark ? "dark-mode" : null}>
      <DataContext.Provider value={data}>
        <StyleProvider value={{isDark, changeTheme}}>
          <Header />
          <Greeting />
          <About />
          <Reels />
          <Gallery />
          <Contact />
          <Footer />
          <Top />
        </StyleProvider>
      </DataContext.Provider>
    </div>
  );
};

export default Main;
