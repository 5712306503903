import React, {useContext} from "react";
import {useMediaQuery} from "react-responsive";
import "./Gallery.scss";
import {Fade} from "react-reveal";
import StyleContext from "../../contexts/StyleContext";
import DataContext from "../DataContext";
import Slider from "react-slick";

const formatGalleryImagesData = data => {
  const formattedGalleryImagesData = [];
  for (let i = 0; i < 10; i++) {
    const imageUrl = data[`galleryImageUrl${i + 1}`] || null;
    if (Boolean(imageUrl)) formattedGalleryImagesData.push(imageUrl);
  }
  return formattedGalleryImagesData;
};

export default function Gallery() {
  const isMobile = useMediaQuery({
    query: "(max-width:758px)"
  });
  const {isDark} = useContext(StyleContext);
  const data = useContext(DataContext);
  const {
    galleryTitle,
    gallerySubtitle,
    galleryImageUrl1,
    galleryImageUrl2,
    galleryImageUrl3,
    galleryImageUrl4,
    galleryImageUrl5,
    galleryImageUrl6,
    galleryImageUrl7,
    galleryImageUrl8,
    galleryImageUrl9,
    galleryImageUrl10,
    quickBlurbFlickrUrl
  } = data[0] || {};

  const imagesUrls = formatGalleryImagesData({
    galleryImageUrl1,
    galleryImageUrl2,
    galleryImageUrl3,
    galleryImageUrl4,
    galleryImageUrl5,
    galleryImageUrl6,
    galleryImageUrl7,
    galleryImageUrl8,
    galleryImageUrl9,
    galleryImageUrl10
  });

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    autoplay: true,
    centerPadding: "10px",
    speed: 500,
    slidesToShow: isMobile ? 1 : 2,
    slidesToScroll: 1
  };

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main" id="gallery">
        <div className="gallery-main-div">
          <div className="gallery-header">
            <h1
              className={
                isDark
                  ? "dark-mode heading gallery-heading"
                  : "heading gallery-heading"
              }
            >
              {galleryTitle}
            </h1>
            <p
              className={
                isDark
                  ? "dark-mode subTitle gallery-subtitle"
                  : "subTitle gallery-subtitle"
              }
            >
              {gallerySubtitle}
            </p>
          </div>
          <div className="gallery-cards-div">
            <Slider {...settings}>
              {imagesUrls.map(url => {
                return (
                  <div className="gallery-cards-card-div" key={url}>
                    <img
                      src={url}
                      alt={"Actor's portrait"}
                      className="gallery-cards-image"
                    />
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="gallery-view-more">
            <a
              href={quickBlurbFlickrUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              View More
            </a>
          </div>
        </div>
      </div>
    </Fade>
  );
}
