import React, {useContext} from "react";
import "./SocialMedia.scss";
import {socialMediaLinks} from "../../portfolio";
import DataContext from "../../containers/DataContext";

export default function SocialMedia() {
  const data = useContext(DataContext);

  const {
    quickBlurbInstagramUrl,
    quickBlurbFlickrUrl,
    quickBlurbTwitterUrl,
    quickBlurbYouTubeUrl,
    quickBlurbEmail
  } = data[0] || {};

  if (!socialMediaLinks.display) {
    return null;
  }
  return (
    <div className="social-media-div">
      {quickBlurbInstagramUrl ? (
        <a
          href={quickBlurbInstagramUrl}
          className="icon-button instagram"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram"></i>
          <span></span>
        </a>
      ) : null}

      {quickBlurbFlickrUrl ? (
        <a
          href={quickBlurbFlickrUrl}
          className="icon-button flickr"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-flickr"></i>
          <span></span>
        </a>
      ) : null}

      {quickBlurbTwitterUrl ? (
        <a
          href={quickBlurbTwitterUrl}
          className="icon-button twitter"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-twitter"></i>
          <span></span>
        </a>
      ) : null}

      {quickBlurbYouTubeUrl ? (
        <a
          href={quickBlurbYouTubeUrl}
          className="icon-button youtube"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-youtube"></i>
          <span></span>
        </a>
      ) : null}

      {quickBlurbEmail ? (
        <a
          href={`mailto:${quickBlurbEmail}`}
          className="icon-button google"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-google"></i>
          <span></span>
        </a>
      ) : null}
    </div>
  );
}
