import React, {useContext} from "react";
import {Fade} from "react-reveal";
import "./Greeting.scss";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";

import StyleContext from "../../contexts/StyleContext";
import DataContext from "../DataContext";

export default function Greeting() {
  const {isDark} = useContext(StyleContext);
  const data = useContext(DataContext);
  const {quickBlurbTitle, quickBlurbContent, quickBlurbPhotoUrl, resumeLink} =
    data[0] || {};

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-image-div">
            <img alt="Devin's Headshot" src={quickBlurbPhotoUrl}></img>
          </div>
          <div className="greeting-text-div">
            <div>
              <h1
                className={isDark ? "dark-mode greeting-text" : "greeting-text"}
              >
                {quickBlurbTitle}
              </h1>
              <p
                className={
                  isDark
                    ? "dark-mode greeting-text-p"
                    : "greeting-text-p subTitle"
                }
              >
                {quickBlurbContent}
              </p>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="#contact" />
                <Button text="See my resume" newTab={true} href={resumeLink} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}
