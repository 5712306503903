import React, {useContext} from "react";
import "./About.scss";
import {Fade} from "react-reveal";

import StyleContext from "../../contexts/StyleContext";
import DataContext from "../DataContext";

export default function About() {
  const {isDark} = useContext(StyleContext);
  const data = useContext(DataContext);
  const {aboutTitle, aboutContent, aboutPhotoUrl} = data[0] || {};

  return (
    <div className={isDark ? "dark-mode main" : "main"} id="about">
      <Fade right duration={1000}>
        <div className="about-text-div">
          <h1 className={isDark ? "dark-mode about-heading" : "about-heading"}>
            {aboutTitle}
          </h1>

          <div className="about-content-container">
            <div className="about-paragraphs-container">
              {aboutContent
                ? aboutContent.split("/n").map(paragraphText => {
                    return (
                      <p
                        key={paragraphText}
                        className={
                          isDark
                            ? "dark-mode subTitle about-text"
                            : "subTitle about-text"
                        }
                      >
                        {paragraphText}
                      </p>
                    );
                  })
                : null}
            </div>

            <div className="about-image-div">
              <img alt="Devin in K-pop hoodie" src={aboutPhotoUrl} />
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );
}
